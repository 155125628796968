'use client';

import { useEffect } from 'react';
import { create } from 'zustand';
import { CampaignSpotApprovalLevelType, CampaignSpotType } from '@/packages/api/resources/v1/campaign';

type CampaignStore = {
  spots: CampaignSpotType[];
  setSpots: (spots: CampaignSpotType[]) => void;
  updateSpot: (spotId: string, spot: { [key: string]: string | number | CampaignSpotApprovalLevelType[] }) => void;
};

export const useCampaignStore = create<CampaignStore>((set, get) => ({
  spots: [],
  setSpots: (spots: CampaignSpotType[]) => set({ spots }),
  updateSpot: (spotId: string, spot: { [key: string]: string | number | CampaignSpotApprovalLevelType[] }) => {
    const currentSpots = get().spots || [];
    const newSpots = [...currentSpots];
    const index = newSpots.findIndex(a => String(a.spotId) === String(spotId));

    if (index > -1) {
      newSpots[index] = {
        ...newSpots[index],
        ...spot,
      };
    }

    set({ spots: newSpots });
  },
}));

export const InitializeCampaignStore = ({ spots }: { spots: CampaignSpotType[] }) => {
  useEffect(() => {
    useCampaignStore.setState({ spots });
  }, [spots]);

  return null;
};

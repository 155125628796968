import { fetchFile } from '@ffmpeg/util';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { AudioTrackRegionType, AudioTrackType, FFmpegFilterOptions } from './types';
import { CampaignSpotTrackType, CampaignSpotTrackFiltersType } from '../api/resources/v1/campaign';
import { ConfigAudioTemplateType } from '../api/resources/v1/config/audioTemplate';

export async function files(items: Array<{ data?: string }>, ffmpeg: FFmpeg) {
  const inputs: string[] = [];

  for (let i = 0; i < items.length; i++) {
    const track = items[i];
    const filename = `input${i}.mp3`;
    inputs.push('-i', filename);
    await ffmpeg.writeFile(filename, await fetchFile(track.data));
  }

  return inputs;
}

export function stringifyFFmpegParams(obj: any, orderedKeys: string[] = []) {
  const sortedEntries = Object.entries(obj).sort(([keyA], [keyB]) => {
    const indexA = orderedKeys.indexOf(keyA);
    const indexB = orderedKeys.indexOf(keyB);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    } else {
      return keyA.localeCompare(keyB);
    }
  });

  return sortedEntries.map(([key, value]) => `${key}=${key === 'adelay' ? `${value}:all=1` : value}`).join(',');
}

export function generateFilterComplex(regions: AudioTrackRegionType[], mastering: string[] = []) {
  const args = ['-filter_complex'];

  // Filter Graph

  const filters = regions.map((region, i) => {
    let regionFilter = `[${i}]${stringifyFFmpegParams(region.filters, ['atempo', 'adelay'])}`;

    // Resample each audio track to 44.1 kHz
    regionFilter += ',aresample=44100';

    // when the audio is mono, apply mono to stereo technique
    if (region.channels === 1) {
      regionFilter += ',pan=stereo|c0=c0|c1=c0';
    }

    return `${regionFilter}[a${i}];`;
  });

  const inputs = [...Array(regions.length)]
    .map((u, i) => i)
    .reverse()
    .reduce((a, b) => `[a${b}]${a}`, '');

  filters.push(`${inputs}amix=inputs=${regions.length}:duration=longest`);

  // Mastering
  filters.push(`,${mastering.join(',')}`);

  args.push(filters.join(''));

  return args;
}

export function filter(options: FFmpegFilterOptions = {}) {
  const args = ['-af'];
  args.push(stringifyFFmpegParams(options));
  return args;
}

export function getAutoADelay(automaticADelayTrackId: string, spotTracks: CampaignSpotTrackType[], audioTemplate: ConfigAudioTemplateType) {
  const spotTrack = spotTracks.find(a => Number(a.audioTemplateTrackConfigId) === Number(automaticADelayTrackId));
  if (!spotTrack) return '0';

  const audioTemplateTrack = audioTemplate.tracks?.find(audioTemplateTrack => Number(audioTemplateTrack.id) === Number(spotTrack.audioTemplateTrackConfigId));
  const filtersApplied: CampaignSpotTrackFiltersType = spotTrack.filters ? JSON.parse(spotTrack.filters) : {};
  const adelay = filtersApplied.adelay ? Number(filtersApplied.adelay) : audioTemplateTrack?.adelay ? Number(audioTemplateTrack.adelay) : 0;
  const atempo = filtersApplied.atempo ? Number(filtersApplied.atempo) : 1;
  const duration = (spotTrack.audioInfo.duration || 0) * 1000;

  const autoADelay = String(adelay + duration / atempo);
  return autoADelay;
}

export async function prepareTracksForMixing(audioTemplate: ConfigAudioTemplateType, spotTracks: CampaignSpotTrackType[] | undefined = []): Promise<AudioTrackType[]> {
  const audioTracks: AudioTrackType[] = [];
  const audioTemplateTracks = audioTemplate.tracks || [];

  for (let i = 0; i < audioTemplateTracks.length; i++) {
    const audioTemplateTrack = audioTemplateTracks[i];
    const spotTrack = spotTracks.find(a => Number(a.audioTemplateTrackConfigId) === Number(audioTemplateTrack.id));

    if (spotTrack && spotTrack.assetId) {
      const metadata = spotTrack.audioInfo;
      const audioDuration = metadata.duration * 1000;
      const channels = metadata.numberOfChannels || 1;

      const filtersApplied: CampaignSpotTrackFiltersType = spotTrack.filters ? JSON.parse(spotTrack.filters) : {};

      // track volume
      const volume = filtersApplied.volume ? String(filtersApplied.volume) : '1';

      // adelay // audio offset in the mix
      let adelay = '0';
      if (filtersApplied.adelay) {
        adelay = String(filtersApplied.adelay);
      } else if (audioTemplateTrack.isAutomaticADelay && audioTemplateTrack.automaticADelayTrackId) {
        // automatic delay
        adelay = getAutoADelay(audioTemplateTrack.automaticADelayTrackId, spotTracks, audioTemplate);
      } else if (audioTemplateTrack.adelay) {
        adelay = String(audioTemplateTrack.adelay);
      }

      // atempo
      let atempo = '1.00';
      if (filtersApplied.atempo) {
        // prioritize applied atempo filter
        atempo = filtersApplied.atempo;
      } else if (audioTemplateTrack.duration?.durationMax && audioDuration > audioTemplateTrack.duration.durationMax) {
        // check for maximum duration
        atempo = String(Math.min(audioDuration / audioTemplateTrack.duration.durationMax, 2.0));
      } else if (audioTemplateTrack.duration?.durationMin && audioTemplateTrack.duration.durationMin && audioDuration < audioTemplateTrack.duration.durationMin) {
        // check for minimum duration
        atempo = String(Math.max(audioDuration / audioTemplateTrack.duration.durationMin, 0.5));
      }

      // console.debug('ℹ️', {
      //   audioTemplateTrackId: track.audioTemplateTrackId,
      //   duration: {
      //     default: audioTemplateTrack.duration,
      //     audio: audioDuration,
      //   },
      //   adelay: {
      //     default: audioTemplateTrack.adelay,
      //     applied: filtersApplied.adelay,
      //   },
      //   atempo: {
      //     default: atempo,
      //     applied: filtersApplied.atempo,
      //   },
      //   volume,
      // });

      audioTracks.push({
        name: String(audioTemplateTrack.name),
        trackId: String(spotTrack.trackId),
        regions: [
          {
            id: String(spotTrack.trackId),
            channels,
            filters: {
              volume,
              adelay,
              atempo,
            },
          },
        ],
      });
    }
  }

  return audioTracks;
}

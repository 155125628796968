export const enum MIMEType {
  mp3 = 'audio/mp3',
  mpeg = 'audio/mpeg',
  wav = 'audio/wav',
  javascript = 'text/javascript',
  wasm = 'application/wasm',
  zip = 'application/zip',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export type FFmpegFilterOptions = {
  asetrate?: string;
  aresample?: string;
  atempo?: string;
};

export type FFmpegFilterComplexOptions = {
  volume?: string;
  adelay?: string;
};

export type AudioFileType = {
  id?: string;
  data?: string;
  // metadata: any;
  // type: string;
  // new?: boolean;
  // text?: {
  //   text: string;
  //   words: string[];
  //   sentences: string[];
  // };
};

export type AudioTrackRegionFiltersType = {
  adelay?: string;
  volume?: string;
  atempo?: string;
  aresample?: string;
  asetrate?: string;
};

export type AudioTrackRegionType = {
  channels: number;
  filters: AudioTrackRegionFiltersType;
} & AudioFileType;

export type AudioTrackType = {
  name: string;
  trackId: string;
  regions: Array<AudioTrackRegionType>;
  data?: string;
};

export const dictionaryFFmpegLog = new Map();
dictionaryFFmpegLog.set('I', 'Integrated Loudness');
dictionaryFFmpegLog.set('Threshold', 'Threshold');
dictionaryFFmpegLog.set('LRA', 'LRA (Loudness Range)');
dictionaryFFmpegLog.set('LRA low', 'LRA low');
dictionaryFFmpegLog.set('LRA high', 'LRA high');

import { useState, useEffect } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { prepareTracksForMixing } from './helpers';
import { CampaignSpotTrackType } from '../api/resources/v1/campaign';
import { ConfigAudioTemplateType } from '../api/resources/v1/config/audioTemplate';
import { applicationInsights, TRACK_EVENTS } from '../azure/ApplicationInsights';
import { amix } from './ffmpeg';
import api from '../api/api';
import { Logger } from '../utils/log';
import { startPerformance } from '../utils/performance';

export const useFFmpeg = () => {
  const [ffmpeg, setFFmpeg] = useState<FFmpeg | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ffmpegIsReady, setFFmpegIsReady] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const loadFFmpeg = async () => {
    try {
      if (window.ffmpeg && window.ffmpeg.loaded) {
        setFFmpeg(window.ffmpeg);
        setIsLoading(false);
        return;
      }

      const ffmpegInstance = new FFmpeg();
      // ffmpegInstance.on('log', ({ message }) => Logger('info', '🎥 ffmpeg', 'message:', message));
      // ffmpegInstance.on('progress', (progress: any) =>
      //   Logger('info', '🎥 ffmpeg', 'progress:', progress)
      // );

      await ffmpegInstance.load();
      Logger('info', `🖥️ ffmpeg`, 'loaded FFmpeg');

      // Store ffmpeg in window object
      window.ffmpeg = ffmpegInstance;
      setFFmpeg(ffmpegInstance);
      setIsLoading(false);
      setFFmpegIsReady(true);
    } catch (err) {
      setError(`Error when loading FFmpeg: ${err}`);
      setIsLoading(false);
    }
  };

  const unloadFFmpeg = async () => {
    // dispose ffmpeg
    if (window.ffmpeg) {
      window.ffmpeg.terminate();
      // @ts-ignore
      window.ffmpeg = null;
      Logger('info', `🖥️ ffmpeg`, 'unload FFmpeg');
    }
  };

  // initially load FFmpeg
  useEffect(() => {
    loadFFmpeg();
  }, []);

  const listDirectory = async () => {
    if (!window.ffmpeg) await loadFFmpeg();
    return window.ffmpeg?.listDir('/');
  };

  const createDirectory = async (directory: string) => {
    if (!window.ffmpeg) await loadFFmpeg();
    return window.ffmpeg?.createDir(directory);
  };

  const writeFile = async (name: string, url: string) => {
    if (!window.ffmpeg) await loadFFmpeg();
    const filename = `${name}.mp3`;
    const response = await window.ffmpeg?.writeFile(`${filename}`, await fetchFile(url));
    const directory = await listDirectory();
    Logger('info', `🖥️ ffmpeg`, 'write file', { filename, directory });
    return response;
  };

  const deleteFiles = async (spotId: string, tracks: CampaignSpotTrackType[]) => {
    if (!window.ffmpeg) await loadFFmpeg();

    // delete spot track files
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i];
      const exists = await fileExists(String(track.trackId));
      if (exists) {
        await window.ffmpeg?.deleteFile(`${track.trackId}.mp3`);
      }
    }

    // delete spot merged file
    const mixedAudioExists = await fileExists(String(spotId));
    if (mixedAudioExists) {
      await window.ffmpeg?.deleteFile(`${String(spotId)}.mp3`);
    }
  };

  const clearDirectory = async () => {
    if (!window.ffmpeg) await loadFFmpeg();

    const getPerformanceResult = startPerformance();
    const dir = await window.ffmpeg?.listDir('/') || [];

    for (let i = 0; i < dir.length; i++) {
      const file = dir[i];

      if (file.name?.includes('.mp3')) {
        console.log(`delete ${file.name}`);
        await window.ffmpeg?.deleteFile(file.name);
      }
    }

    const directory = await listDirectory();
    Logger('info', `🖥️ ffmpeg`, 'clear directory', { directory, averageTime: getPerformanceResult() });
  };

  const writeFiles = async (tracks: CampaignSpotTrackType[]) => {
    if (!window.ffmpeg) await loadFFmpeg();

    const getPerformanceResult = startPerformance();

    await Promise.all(
      tracks.map(async track => {
        await writeFile(String(track.trackId), api.media.stream(String(track.assetId)));
        console.log(`Successfully wrote ${track.trackId} into the file system`);
        console.log(api.media.stream(String(track.assetId)));
      })
    );

    Logger('info', `🖥️ ffmpeg`, 'writeFiles', { averageTime: getPerformanceResult() });
  };

  const fileExists = async (name: string) => {
    if (!window.ffmpeg) await loadFFmpeg();

    try {
      const filename = `${name}.mp3`;
      await window.ffmpeg?.readFile(`${filename}`);
      return true;
    } catch (error) {
      return false;
    }
  };

  const mixFiles = async (spotId: string, audioTemplate: ConfigAudioTemplateType, tracks: CampaignSpotTrackType[]) => {
    if (!window.ffmpeg) await loadFFmpeg();

    const getPerformanceResult = startPerformance();
    Logger('info', `🖥️ ffmpeg`, 'Mix started', { spotId, startTime: performance?.now().toLocaleString() });

    const preparedTracks = await prepareTracksForMixing(audioTemplate, tracks);
    const mix = await amix(spotId, preparedTracks, audioTemplate);

    const averageTime = getPerformanceResult();

    Logger('info', `🖥️ ffmpeg`, 'Mix finished', { spotId, endTime: performance?.now().toLocaleString(), averageTime: getPerformanceResult() });

    await unloadFFmpeg();

    applicationInsights.trackMetric({ name: 'EditorAudioMixing', average: averageTime });
    applicationInsights.trackEvent({
      name: TRACK_EVENTS.SpotMix,
      properties: {
        preparedTracks,
        audioTemplate,
        averageTime,
      },
    });

    return mix;
  };

  return {
    ffmpeg,
    ffmpegIsReady,
    isLoading,
    error,
    clearDirectory,
    listDirectory,
    createDirectory,
    writeFile,
    writeFiles,
    deleteFiles,
    fileExists,
    mixFiles,
  };
};

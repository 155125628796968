'use client';

import { fetchFile } from '@ffmpeg/util';
import { generateFilterComplex, files } from './helpers';
import { AudioTrackType, AudioTrackRegionType } from './types';
import { ConfigAudioTemplateType } from '../api/resources/v1/config/audioTemplate';
import { Logger } from '../utils/log';

const ffmpegExec = async (items: AudioTrackRegionType[], args: string[], output = 'output.mp3') => {
  const ffmpeg = window.ffmpeg;
  const inputs = await files(items, ffmpeg);
  Logger('info', `🖥️ ffmpeg`, 'exec()', [...inputs, ...args, output]);
  await ffmpeg.exec([...inputs, ...args, output]);
  return ffmpeg.readFile(output);
};

export const amix = async (spotId: string, tracks: AudioTrackType[], audioTemplate: ConfigAudioTemplateType) => {
  const logs: { [key: string]: string } = {};
  const output = `${spotId}.mp3`;
  const regions = tracks.reduce((acc, track) => [...acc, ...track.regions], [] as AudioTrackRegionType[]);
  const filterComplex = audioTemplate.filters ? generateFilterComplex(regions, JSON.parse(audioTemplate.filters)) : [];
  const audioOutput = audioTemplate.output ? JSON.parse(audioTemplate.output) : [];
  const args = [...filterComplex, ...audioOutput];

  const logger = ({ message }: any) => {
    const parts = message.split(':');
    const k = parts[0]?.trim();
    const v = parts[1]?.trim();
    const keys = ['I', 'LRA', 'LRA high', 'LRA low', 'Peak', 'Threshold'];
    if (keys.includes(k) && v) logs[k] = v;
  };

  // exec

  const ffmpeg = window.ffmpeg;
  ffmpeg.on('log', logger);

  console.log(await ffmpeg.listDir('/'));

  const inputs = tracks.map(track => ['-i', `${track.trackId}.mp3`]).flat();
  Logger('info', `🖥️ ffmpeg`, 'exec()', [...inputs, ...args, output]);
  await ffmpeg.exec([...inputs, ...args, output]);
  await ffmpeg.exec(['-i', output, '-af', 'ebur128=peak=true', '-f', 'null', 'NULL']);

  ffmpeg.off('log', logger);

  const file = await ffmpeg.readFile(output);
  return { file, logs };
};

export const splitRegion = async (region: AudioTrackRegionType, currentTime: number) => {
  const adelay = Number(region.filters.adelay) / 1000 || 0;
  const cutTime = currentTime - adelay;

  const part1Args = ['-ss', '0', '-to', String(cutTime), '-c', 'copy'];
  const data1 = await ffmpegExec([region], part1Args);
  const part2Args = ['-ss', String(cutTime), '-c', 'copy'];
  const data2 = await ffmpegExec([region], part2Args);

  return { data1, data2 };
};

export const getAudioLoudness = async (b64: string, timestamp = '00:00:25') => {
  const logs: { [key: string]: string } = {};

  const logger = ({ message }: any) => {
    const parts = message.split(':');
    const k = parts[0]?.trim();
    const v = parts[1]?.trim();
    const keys = ['I', 'LRA', 'LRA high', 'LRA low', 'Peak', 'Threshold'];
    if (keys.includes(k) && v) logs[k] = v;
  };

  // exec

  const ffmpeg = window.ffmpeg;
  ffmpeg.writeFile('input.mp3', await fetchFile(b64));

  ffmpeg.on('log', logger);
  await ffmpeg.exec(['-ss', timestamp, '-i', 'input.mp3', '-af', 'ebur128=peak=true', '-f', 'null', 'NULL']);
  ffmpeg.off('log', logger);

  return logs;
};

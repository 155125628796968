import { UserType } from '../../app/(pages)/account/types';
import { CampaignSpotType, CampaignStatusType, CampaignSpotApprovalLevelType } from '../api/resources/v1/campaign';

export type SignalRMixingRequestedPayload = {
  campaignId: string;
  audioTemplateId: string;
  assetId: string;
  spotCategoryId: string;
  taskHistoryId: string;
  allTracksHasAudio: boolean;
} & CampaignSpotType;

export type SignalRMixingCompletedPayload = {
  spotId: string;
  campaignId: string;
  assetId: string;
  mergedAudioAssetId: string;
};

export type SignalRCampaignStatusChanged = {
  campaignId: string;
  releaseStatus: CampaignStatusType;
};

export type SignalRPeriodStatusChanged = {
  period: string;
  periodStatus: CampaignStatusType;
};

export type SignalRSpotStatusChanged = {
  spotId: string;
  approvalLevels: Array<CampaignSpotApprovalLevelType>;
};

export type SignalRComment = {
  commentId: string;
  spotId: string;
  content: string;
  createdBy: UserType;
  createdAt: string;
  modifiedAt: string;
};

export type SignalRComments = Array<SignalRComment>;

export type SignalRNotification = {
  notificationId: string;
  notificationType:
    | 'NotSet'
    | 'NewCommentCreated'
    | 'AllSpotsInPeriodWeekApprovedAtOneStage'
    | 'AllSpotsInPeriodWeekReadyToRelease'
    | 'SpotReadyForReviewer'
    | 'SpotDiscarded'
    | 'AddedSpotToCampaignStage2OrHigher';
  content: string;
  createdAt: string;
  isRead: boolean;
  metadata: string;
};

export type SignalRNotificationCreated = {
  receiver: string;
  receiverUserId: string;
} & SignalRNotification;

export type SignalRNotifications = Array<SignalRNotification>;

export const enum SignalREvent {
  ReceiveMessage = 'ReceiveMessage',
  SpotMixingRequested = 'SpotMixingRequested',
  SpotMixingCancelled = 'SpotMixingCancelled',
  SpotMixingCompleted = 'SpotMixingCompleted',
  ClientTaskFailed = 'ClientTaskFailed',
  SpotStatusChanged = 'SpotStatusChanged',
  SubscribeSpot = 'SubscribeSpot',
  unsubscribeSpot = 'unsubscribeSpot',
  AddComment = 'AddComment',
  CommentAdded = 'CommentAdded',
  CommentUpdated = 'CommentUpdated',
  SubscribeUser = 'SubscribeUser',
  UnsubscribeUser = 'UnsubscribeUser',
  NotificationCreated = 'NotificationCreated',
  NotificationUpdated = 'NotificationUpdated',
  MarkNotificationAsRead = 'MarkNotificationAsRead',
  CampaignStatusChanged = 'CampaignStatusChanged',
  PeriodStatusChanged = 'PeriodStatusChanged',
}

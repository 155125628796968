'use client';

import React, { createContext, useContext, useState, useEffect } from 'react';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { Logger } from '@/packages/utils/log';
import { applicationInsights } from '../azure/ApplicationInsights';
import { Spinner } from '../ui/Spinner';

const SignalRContext = createContext<HubConnection | null>(null);

export const SignalRProvider = ({ children, token }: { children: React.ReactNode; token?: string }) => {
  const [connection, setConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    const url = `${process.env.NEXT_PUBLIC_SIGNALR_HOST_URL}/chatHub`;

    if (!token) {
      applicationInsights.trackException({ error: new Error(`Found undefined token when connection to SignalR`) });
    }

    const _connection = new HubConnectionBuilder()
      .withUrl(url, { accessTokenFactory: () => String(token) })
      .withAutomaticReconnect([0, 3000, 5000, 10000])
      .configureLogging(LogLevel.Debug)
      .build();

    _connection.onreconnected(connectionId => {
      Logger('info', '🔌 SignalR', `is reconnected. Connection ID: ${connectionId}`);
      applicationInsights.trackTrace({ message: `SignalR reconnected is closed` }, { state: _connection.state, connectionId });
      setConnection(_connection);
    });

    _connection.onclose(error => {
      Logger('info', '🔌 SignalR', `connection is closed`);
      applicationInsights.trackTrace({ message: `SignalR connection is closed` }, { state: _connection.state });
      if (error) {
        console.info(`🔌 SignalR connection is closed due to an error. Error: ${error}`);
      }
    });

    _connection
      .start()
      .then(() => {
        Logger('info', '🔌 SignalR', `connection is ${_connection.state}`);
        setConnection(_connection);
      })
      .catch(error => {
        Logger('error', '🔌 SignalR', `connection error`, error);
        applicationInsights.trackException({ error });
      });
  }, [token]);

  return <SignalRContext.Provider value={connection}>{connection && connection.state === HubConnectionState.Connected ? children : <Connecting />}</SignalRContext.Provider>;
};

function Connecting() {
  return (
    <div className="flex h-svh w-[100svw] items-center justify-center">
      <div className="grid justify-items-center gap-4">
        <Spinner />
        <p className="text-center">SignalR is connecting...</p>
      </div>
    </div>
  );
}

export const useSignalR = () => useContext(SignalRContext);
